import { IPrinterList } from '@/type/print'
export const PRINT_NORMAL: Array<IPrinterList> = [
	{
		id: 0,
		label: 'ขนาด A4 1:2',
		value: 'NORMAL1:2',
	},
	{
		id: 1,
		label: 'ขนาด A4 1:4',
		value: 'NORMAL1:4',
	},
	{
		id: 2,
		label: 'ขนาด A4 1:6',
		value: 'NORMAL1:6',
	},
]

export const PRINT_TSC: Array<IPrinterList> = [
	{
		id: 3,
		label: 'กระดาษ สติ๊กเกอร์',
		value: 'TSC',
	},
]

export const PAPER_RANG: Array<IPrinterList> = [
	{
		id: 4,
		label: 'PAPERRANG',
		value: 'PAPERRANG',
	},
]

export const ORDER_PRINT_LABEL_STATUS = {
	PENDING: {
		text: 'ยังไม่ได้พิมพ์ใบปะหน้า',
		color: 'danger',
		icon: 'close-bold',
	},
	PRINTED: {
		text: 'พิมพ์ใบปะหน้าแล้ว',
		color: 'success',
		icon: 'close-bold',
	},
}

export const COURIER_NAME = {
	THAIPOST: 'Thailand Post',
	FLASH: 'Flash',
	FLASH_BULKY: 'Flash Bulky',
	'J&T': 'J&T',
	NINJA_VAN: 'Ninja Van',
	KERRY: 'Kerry',
	SPX: 'Shopee',
}

export const LOGO_COURIER = {
	'Thailand Post': {
		src: require('@/assets/print/new_thaipost.webp'),
		width: '80%',
		style: 'width: 50%; height: auto',
	},
	Flash: {
		src: require('@/assets/print/Flash_Expess.webp'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
	'Flash Bulky': {
		// src: require('@/assets/print/flash_bulky.png'),
		src: require('@/assets/print/Flash_Expess.webp'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
	'J&T': {
		src: require('@/assets/print/new-j&t-logo.webp'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
	'Ninja Van': {
		src: require('@/assets/print/ninjavan_logo.webp'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
	Kerry: {
		src: require('@/assets/print/Kerry-logo.png'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
	'Shopee': {
		src: require('@/assets/print/spx.png'),
		width: '40%',
		style: 'width: 70%; height: auto',
	},
}

export const COURIER_NAME_ID = {
	THAIPOST: '62d8d8b3d119d34431f0324b',
	FLASH: '616a5b5a7d33520401450d9f',
	JNT: '616a5b887d33520401450da3',
	NINJA_VAN: '63e4c560c57a1c68c9202d0e',
	KERRY: '616a5b747d33520401450da1',
	SPX: '657834199717e74e6991233b',
	FLASH_BULKY: '651126ea1835ad1bf3b2eb04',
}

export const ORDER_PRINT_STATUS = {
	PENDING: 'PENDING',
	PRINTED: 'PRINTED',
}
