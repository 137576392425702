import { AxiosResponse } from 'axios'
import axios from '../instance'
import { IGetOrderPront } from './type'

export const getOrderPrintById = async (params: IGetOrderPront) => {
	try {
		const { data } = await axios.post('/order/label', params)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const updateStatusLabel = async (bodyData: { orders: string[] }) => {
	try {
		const response = await axios.put('/order/label-status/update', bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const marketplaceLabel = async (bodyData: any): Promise<AxiosResponse<any>> => {
	try {
		const data = await axios.post('/order/marketplace/label', bodyData)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
