import labelmake from 'labelmake'
import { SHIPPING_PATH } from '@/constants/route'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'
import { updateStatusLabel } from '@/api/print/print.service'
import { ElNotification } from 'element-plus'
import { COURIER_NAME, COURIER_NAME_ID } from '@/constants/shippingEnum'
import { FILE_TYPE, useReactNativeEvent } from '@/use/useReactNativeEvent'
import { computed } from 'vue'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import PDFMerger from 'pdf-merger-js/browser'

export const usePrint = () => {
	// Comment Change more comment
	const router = useRouter()
	const shippingStore = useShippingStore()
	const isReactNativeWebview = computed(() => window.ReactNativeWebView)
	const paymentReceiptDate = computed(() => shippingStore.getterPaymentReceiptDate)
	const sliceSorthingFlash = (value: string, status: number): string => {
		const arrSorting = value.split('-')
		switch (status) {
		case 1:
			return arrSorting[0]
		case 2:
			return arrSorting[1].slice(0, 2)
		case 3:
			if (value.length === 12) {
				return arrSorting[1].slice(2, 5)
			}
			return arrSorting[1].slice(2, 7)
		case 4:
			return arrSorting[2]
		default:
			return arrSorting[0]
		}
	}

	const exportToPDF = async (fileName: string, domImageBlob: any, template: any) => {
		const { emitDownloadFile } = useReactNativeEvent()
		const pdf = await labelmake({ template, inputs: domImageBlob })
		const blob = new Blob([pdf.buffer], { type: 'application/pdf' })

		if (window.ReactNativeWebView) {
			// Do In-app browser React native
			const base64 = await new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onloadend = () => {
					const base64String = (reader?.result as any)?.split(',')[1]
					resolve(base64String)
				}
				reader.onerror = reject
				reader.readAsDataURL(blob)
			})
			
			// emit to react native
			emitDownloadFile(base64 as string, FILE_TYPE.pdf)

		} else {
			// DO Desktop browser
			const url = window.URL || window.webkitURL
			const link = url.createObjectURL(blob)
			const a = document.createElement('a')
			const dayNow = dayjs(paymentReceiptDate.value).locale('th').format('DD/MM/YYYY') || 'YYYY-MM-DD'
			a.setAttribute('download', `${dayjs().format(`${dayNow} : HH:mm`)}-${fileName}`)
			a.setAttribute('target','_blank')
			a.setAttribute('href', link)
			document.body.appendChild(a)
			a.click()
		}

	}

	const mergeAndExportPDF = async (fileName: string, domImagesBlob: any[], templates: any[]) => {
		const { emitDownloadFile } = useReactNativeEvent()
		const files: any[] = []
		const url = window.URL || window.webkitURL

		for (let i = 0; i < domImagesBlob.length; i++) {
			const pdf = await labelmake({template: templates[i], inputs: domImagesBlob[i]})
			const blob = new Blob([pdf.buffer], {type: 'application/pdf'})
			
			const link = url.createObjectURL(blob)
			files.push(link)
		}

		const merger = new PDFMerger()

		for (const file of files) {
			await merger.add(file)
		}

		const mergedPdf = await merger.saveAsBlob()
		if (window.ReactNativeWebView) {
			// Do In-app browser React native
			const base64 = await new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onloadend = () => {
					const base64String = (reader?.result as any)?.split(',')[1]
					resolve(base64String)
				}
				reader.onerror = reject
				reader.readAsDataURL(mergedPdf)
			})

			// emit to react native
			emitDownloadFile(base64 as string, FILE_TYPE.pdf)

		} else {
			const downloadUrl = url.createObjectURL(mergedPdf)

			const a = document.createElement('a')
			const dayNow = dayjs(paymentReceiptDate.value).locale('th').format('DD/MM/YYYY') || 'YYYY-MM-DD'
			a.setAttribute('download', `${dayjs().format(`${dayNow} : HH:mm`)}-${fileName}`)
			a.setAttribute('target','_blank')
			a.setAttribute('href', downloadUrl)
			document.body.appendChild(a)
			a.click()
		}

	}

	const slicePhoneNo = (value: string): string => {
		if (!value) return '-'
		return value.slice(0, 3) + 'xxx' + value.slice(6)
	}

	const onUpdatePrint = async (orderID: string | string[]) => {
		try {
			const params = {
				orders:
					typeof orderID === 'string'
						? [orderID]
						: (orderID as string[]),
			}
			const res = await updateStatusLabel(params)
			if (res.updateStatus === 'SUCCESSFUL') {
				ElNotification({
					title: 'สำเร็จ',
					message: 'update สถานะพิมพ์สำเร็จ',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
			}
		} catch (error) {
			ElNotification({
				title: 'ผิดพลาด',
				message: 'ระบบเกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
				type: 'error',
				showClose: !isReactNativeWebview.value
			})
		}
	}

	const productName = (item: any) => {
		if (item?.product?.parent) {
			return (
				item?.product?.parent?.name +
				'(' +
				item?.product?.types.join('-') +
				')'
			)
		}
		return item?.product?.name
	}

	const isVisibleCODAmount = (item: any) => {
		return (
			item.payment &&
			item.payment.channel === 'COD' &&
			item.shop.settingConfig &&
			item.shop.settingConfig.isVisibleCodAmount
		)
	}

	const isVisibleProduct = (item: any) => {
		return (
			item.products &&
			item.products.length > 0 &&
			item.shop.settingConfig &&
			item.shop.settingConfig.isVisibleProduct
		)
	}

	const isVisibleBoxSizeAndWeight = (item: any) => {
		return (
			item.boxSizeMeasuredFormStore &&
			item.boxSizeMeasuredFormStore.height > 0 &&
			item.boxSizeMeasuredFormStore.length > 0 &&
			item.boxSizeMeasuredFormStore.weight > 0 &&
			item.boxSizeMeasuredFormStore.width > 0 &&
			item.shop.settingConfig &&
			item.shop.settingConfig.isVisibleBoxSizeAndWeight
		)
	}

	const onMaskPhoneNumber = (
		item: any,
		isMask: boolean,
		phoneNumber: string
	) => {
		const isThaipost = item?.courier?.name === COURIER_NAME.THAIPOST
		if (isMask && phoneNumber && !isThaipost) {
			let replacePhone: string[] | any = []
			if (phoneNumber.length === 9) {
				replacePhone = phoneNumber
					.replace(/\D/g, '')
					.match(/(\d{3})(\d{3})(\d{3})/)
			} else {
				replacePhone = phoneNumber
					.replace(/\D/g, '')
					.match(/(\d{3})(\d{4})(\d{3})/)
			}
			return `${replacePhone[1]}-XXXX-${replacePhone[3]}`
		}
		return phoneNumber
	}

	const isHeaderBooking = (item: any) => {
		const isFlash =
			item.courier &&
			(item.courier.name === COURIER_NAME.FLASH || item.courier.name === COURIER_NAME.FLASH_BULKY)
		return isFlash
	}

	const isSortingCode = (item: any) => {
		const isCourierSortCode =
			item.courier.name === COURIER_NAME.FLASH ||
			item.courier.name === COURIER_NAME['J&T'] ||
			item.courier.name === COURIER_NAME.NINJA_VAN ||
			item.courier.name === COURIER_NAME.SPX
		return isCourierSortCode
		// return item.customField && isCourierSortCode
	}

	const isThaiPost = (item: any) => {
		return item.courier.name === COURIER_NAME.THAIPOST
	}

	const isJAndT = (item: any) => {
		return item.courier.name === COURIER_NAME['J&T']
	}

	const isFlash = (item: any) => {
		return item.courier.name === COURIER_NAME.FLASH
	}

	const isNinja = (item: any) => {
		return item.courier.name === COURIER_NAME.NINJA_VAN
	}

	const isKerry = (item: any) => {
		return item.courier.name === COURIER_NAME.KERRY
	}

	const isJntAndKerry = (item: any) => {
		return (
			item.courier.name === COURIER_NAME['J&T'] ||
			item.courier.name === COURIER_NAME.KERRY
		)
	}

	const isNinjaAndKerry = (item: any) => {
		return (
			item.courier.name === COURIER_NAME.NINJA_VAN ||
			item.courier.name === COURIER_NAME.KERRY
		)
	}

	const isSortingLineCode = (item: any) => {
		const isFlashLineCode =
			item.customField &&
			item.customField.sortingLineCode &&
			isFlash(item)
		const isLntStreetSorting =
			item.customField &&
			item.customField.streetSortingNo &&
			isJAndT(item)
		return isFlashLineCode || isLntStreetSorting
	}

	const isSPX = (item: any) => {
		return item.courier.name === COURIER_NAME.SPX
	}

	const mapCourierName = (courierName: string) => {
		switch (courierName) {
		case COURIER_NAME_ID.JNT:
			return 'J&T'
		case COURIER_NAME_ID.THAIPOST:
			return 'ไปรษณีย์ไทย'
		case COURIER_NAME_ID.NINJA_VAN:
			return 'Ninja Van'
		case COURIER_NAME_ID.KERRY:
			return 'Kerry'
		case COURIER_NAME_ID.SPX:
			return 'Shopee'
		default:
			return 'ไปรษณีย์ไทย'
		}
	}

	const isCourierBlockEditOrder = (courierId: string, printLabelStatus: string | undefined) => {
		const isCourierBlock = courierId === COURIER_NAME_ID.THAIPOST || courierId === COURIER_NAME_ID.JNT || courierId === COURIER_NAME_ID.NINJA_VAN || courierId === COURIER_NAME_ID.KERRY
		return printLabelStatus === 'PRINTED' && isCourierBlock
	}

	const onMarkingShipmentPhoneNo = (
		item: any,
		isMask: boolean,
		phoneNumber: string
	) => {
		const isThaipost = item?.courier?.name === COURIER_NAME.THAIPOST
		const isNinja = item?.courier?.name === COURIER_NAME.NINJA_VAN
		if (isMask && phoneNumber && !isThaipost && !isNinja) {
			let replacePhone: string[] | any = []
			if (phoneNumber.length === 9) {
				replacePhone = phoneNumber
					.replace(/\D/g, '')
					.match(/(\d{3})(\d{3})(\d{3})/)
			} else {
				replacePhone = phoneNumber
					.replace(/\D/g, '')
					.match(/(\d{3})(\d{4})(\d{3})/)
			}
			return `${replacePhone[1]}-XXXX-${replacePhone[3]}`
		}
		return phoneNumber
	}

	const spxServiceType = (type: string) => {
		if(!type) return ''
		return type === 'DROP_OFF' ? 'DROP OFF' : 'PICK UP'
	}

	return {
		sliceSorthingFlash,
		exportToPDF,
		slicePhoneNo,
		onUpdatePrint,
		productName,
		isVisibleCODAmount,
		isVisibleProduct,
		onMaskPhoneNumber,
		isHeaderBooking,
		isSortingLineCode,
		isSortingCode,
		isThaiPost,
		isFlash,
		isJAndT,
		isNinja,
		mapCourierName,
		isCourierBlockEditOrder,
		onMarkingShipmentPhoneNo,
		isKerry,
		isJntAndKerry,
		isNinjaAndKerry,
		isSPX,
		spxServiceType,
		mergeAndExportPDF,
		isVisibleBoxSizeAndWeight
	}
}
